import React from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from "../components/layout"
import ContactForm from "../components/contact-form"

import Jumbotron from "react-bootstrap/Jumbotron"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Button from "react-bootstrap/Button"

import contact from "../images/contact.svg"
import celebrate from "../images/celebrate.svg"
import faMobile from "../images/fa-mobile-solid.svg"

const Contact = props => {
  return(
    <Layout>
      <GatsbySeo
        title="Get In Touch"
        description="Get in touch to book a therapy appointment or find out more about counselling"
        canonical="https://yourtherapydoncaster.co.uk/contact"
        openGraph={{
         url: "https://yourtherapydoncaster.co.uk/contact",
         title: "Get in touch",
         description: "Get in touch to book a therapy appointment or find out more about counselling",
         site_name: "YourTherapy",
       }}
      />
      <Jumbotron fluid className="bg-white">
        <Container>
          <Row>
            <Col xs={12} sm={{span:10,offset:1}} md={{span:5,offset:1}} xl={{span:4,offset:1}}>
              <h1>Contact</h1>
              <p className="my-4">
                Book an appointment, or reach out for more information. I'm always happy to hear from you. </p>
              <p class="text-uppercase font-weight-bolder">Phone</p>
              <Button href="tel:+447305160670" className="mt-2 mb-3 mx-auto text-uppercase py-2 px-4 shadow-sm" variant="outline-dark">
                <Image height="20" width="20" src={faMobile} style={{marginRight:10}} alt=""/>
                0771 1852 707
              </Button>
              <p class="small">I may not be able to answer the phone
                immediately outside of prearranged telephone appointments but I'll always call you back as soon as possible.
                We can also make arrangements over text, WhatsApp and through any of my social media channels
              </p>
            </Col>
            <Col xs={12} sm={{span:10,offset:1}} md={{span:6,offset:0}} xl={{span:6,offset:1}} className="p-3 p-md-0">
              <Image src={contact} fluid alt=""/>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container>
        <Row className="my-3">
          <Col xs={12} sm={{span:10,offset:1}} md={{span:5,offset:0}} xl={{span:4,offset:1}}>
            <p class="text-uppercase font-weight-bolder">Email</p>
            <p>If you prefer email, or you'd like me to ring you at a certain time,
            I'm happy to make arrangements that way too. Please just fill in this
            form and I'll be in touch soon</p>
          </Col>
          <Col xs={12} sm={{span:10,offset:1}} md={{span:6,offset:1,order:'first'}} xl={{span:6}} className="mt-3 mt-md-0 px-3">
            <ContactForm />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xs={12} sm={{span:10,offset:1}} md={{span:5, offset:7}} className="d-flex flex-column align-content-center mb-3 celebrate">
            <Image src={celebrate} fluid alt="" />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Contact

import React from "react"

import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

const ContactForm = props => {
  return(
    <Form name="Contact Form" method="POST" data-netlify="true" action="/thank-you" >
      <input type="hidden" name="form-name" value="Contact Form" />
      <Form.Row>
        <Col xs={12} md={6}>
          <Form.Label htmlFor="contact-name">Name* </Form.Label>
          <Form.Control placeholder="Just your first name is OK" name="contact-name" id="contact-name" required />
        </Col>
        <Col xs={12} md={6}>
          <Form.Label htmlFor="contact-phone">Phone</Form.Label>
          <Form.Control placeholder="Mobile or landline" type="tel" name="contact-phone" id="contact-phone"/>
        </Col>
      </Form.Row>
      <Form.Row className="my-3">
        <Col xs={12}>
          <Form.Label htmlFor="contact-email">Email</Form.Label>
          <Form.Control placeholder="Email" type="email" name="contact-email" id="contact-email"/>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12}>
          <Form.Label htmlFor="contact-message">Message* </Form.Label>
          <Form.Control placeholder="Please let me know the best time and method to contact you" as="textarea" name="contact-message" id="contact-message" rows={3} required />
        </Col>
    </Form.Row>
    <Button type="submit" block className="mt-2 mb-3 mx-auto py-2 px-4 shadow-sm" variant="outline-dark">Submit</Button>
    <small class="text-muted form-text">I'll only use this information to contact you. I'll store it securely and never share it with anyone else.</small>
    </Form>
  )
}

export default ContactForm
